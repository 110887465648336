var exports = {};
exports = whitespace;
var fromCode = String.fromCharCode;
var re = /\s/; // Check if the given character code, or the character code at the first
// character, is a whitespace character.

function whitespace(character) {
  return re.test(typeof character === "number" ? fromCode(character) : character.charAt(0));
}

export default exports;